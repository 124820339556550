.playerLayout {
  display: flex;
  position: relative;
  background-color: var(--theme-color);
  height: 100vh;
  align-items: center;
  justify-content: center;

  & button {
    cursor: pointer !important;
  }

  & video {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
  }

  & .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    & .loader {
      position: absolute;
      right: 50%;
      bottom: 50%;
    }

    & .customOverlay {
      position: absolute;
      left: 0%;
      top: 0%;
      min-height: 25%;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );

      & .htmlOverlay {
        color: #ffffff;
      }

      & .textOverlay {
        font-size: 30px;
        color: #ffffff;
        margin: 50px 0 0 100px;
      }
    }

    & .bottomContainer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 3vw;
      box-sizing: border-box;
      background-color: var(--player-controls-background-color);

      display: flex;
      align-items: center;
      flex-direction: column;

      & .controlsContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1vh;
        overflow: hidden;

        & button {
          position: relative;
          all: unset;
          align-items: center;
          justify-content: center;
          background-color: var(--button-second-background-color);
          border-radius: 35%;

          & .jumpTimeText {
            position: absolute;
            color: var(--button-second-text-color);
            width: 10vw;
            height: 10vw;
            text-align: center;
            line-height: 10vw;
            margin: auto;
            font-weight: bold;
            font-size: 2.5vw;
          }

          @media screen and (min-width: 700px) {
            & .jumpTimeText {
              position: absolute;
              color: var(--button-second-text-color);
              width: 6vw;
              height: 6vw;
              text-align: center;
              line-height: 6vw;
              margin: auto;
              font-weight: bold;
              font-size: 1.5vw;
            }
          }

          @media screen and (min-width: 1500px) {
            & .jumpTimeText {
              position: absolute;
              color: var(--button-second-text-color);
              width: 3vw;
              height: 3vw;
              text-align: center;
              line-height: 3vw;
              margin: auto;
              font-weight: bold;
              font-size: 1vw;
            }
          }
        }

        & .playButton {
          background-color: var(--button-main-background-color);
          border-radius: 35%;
        }

        & .playerControls {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex: 1;

          & .volumeButton {
            margin: 0 0 0 1vw;
          }

          & .duration {
            margin: 0 0 0 1vw;
            color: var(--player-controls-text-color);
            font-size: 4vw;
            line-height: 50px;
          }

          @media screen and (min-width: 700px) {
            & .duration {
              margin: 0 0 0 1vw;
              color: var(--player-controls-text-color);
              font-size: 3vw;
              line-height: 50px;
            }
          }

          @media screen and (min-width: 1500px) {
            & .duration {
              margin: 0 0 0 1vw;
              color: var(--player-controls-text-color);
              font-size: 2vw;
              line-height: 50px;
            }
          }

          & .volumeBarContainer {
            cursor: pointer;
            position: relative;
            width: 0;
            transition: width 0.3s ease;
            transform: translateY(52%);
            height: 1vh;
            & > input {
              appearance: none;
              margin: 0;
              padding: 0;
              border: none;
              width: 100%;
              height: 0.1875rem;
              position: absolute;
              left: 0;
              right: 0;
              z-index: 20;
              transform: translateY(-50%);
              background: transparent;

              &::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 0rem;
                width: 0rem;
                background-color: var(--player-controls-slider-color);
                border-radius: 50%;
                border: none;
                transition: all 0.3s ease;
              }

              &::-moz-range-thumb {
                -moz-appearance: none;
                height: 0rem;
                width: 0rem;
                background-color: var(--player-controls-slider-color);
                border-radius: 50%;
                border: none;
                transition: all 0.3s ease;
              }

              &::-moz-range-track {
                background-color: transparent;
              }
            }
            & > div {
              position: absolute;
              z-index: 10;
              transform: translateY(-50%);
              height: 0.5vw;
              width: 100%;
              background-color: grey;
              position: relative;
              border-radius: 50px;
              & .previousVolumeBar {
                background-color: var(--player-controls-slider-color);
                height: 100%;
                border-radius: 50px;
              }
            }
          }

          & .volumeFocus {
            width: 6vw;
            & > input::-webkit-slider-thumb {
              width: 1vw;
              height: 1vw;
            }
            & > input::-moz-range-thumb {
              width: 1vw;
              height: 1vw;
            }
          }
        }

        & .playerSettings {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: end;
          flex: 1;

          & button {
            margin-left: 1vw;
          }
        }

        & .icon {
          width: 5vw;
          height: 5vh;
        }

        & .controls {
          margin-top: 1vh;
          display: flex;
          flex-direction: row;
          justify-content: center;

          & .controlIconText {
            width: 10.5vw;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        & .text {
          text-align: center;
          white-space: initial;
          color: grey;
          font-family: var(--theme-global-fontfamily);
          font-size: 1.4vw;
          transform: scale(0);
          transition: transform 200ms ease-in-out;
          margin-top: 0.5vh;
        }

        & .settingsContainer {
          background-color: var(--player-settings-background-color);
          color: var(--player-settings-text-color);
          display: flex;
          flex-direction: row;
          position: absolute;
          top: 0;
          transform: translate(-20%, calc(-100% - 0.625rem));
          border-radius: 0.3125rem;
          padding: 1rem;
          z-index: 20;

          &::after {
            content: "";
            position: absolute;
            left: 60%;
            transform: translate(63%, 0);
            bottom: -0.5rem;
            width: 0;
            height: 0;
            border-left: 0.6rem solid transparent;
            border-right: 0.6rem solid transparent;
            border-top: 0.6rem solid var(--player-settings-background-color);
          }

          & .settingsColumn {
            flex-direction: column;
            margin: 0 1vw;

            & p {
              font-size: large;
              font-weight: bold;
              text-align: center;
            }

            & .settingsOptions {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              & .settingsOptions {
                margin: 1vh 0;
              }

              & .selectedOptions {
                color: var(--player-settings-text-color);
                font-weight: bold;
                text-decoration: underline;
                margin: 1vh 0;
              }
            }
          }
        }
      }

      & .progressBarContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;

        & .progressBar {
          width: 100%;
          z-index: 10;
          height: 1vh;
          border-radius: 1vh;
          background-color: var(
            --player-controls-progress-bar-background-color
          );
          margin: 0 2vh 0 2vh;
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;

          & > input {
            appearance: none;
            margin: 0;
            padding: 0;
            border: none;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 20;
            background: transparent;

            &::-webkit-slider-thumb {
              -webkit-appearance: none;
              height: 1vh;
              width: 1vh;
              background-color: transparent;
              border: none;
            }

            &::-moz-range-thumb {
              -webkit-appearance: none;
              height: 1vh;
              width: 1vh;
              background-color: transparent;
              border: none;
            }

            &::-ms-thumb {
              -webkit-appearance: none;
              height: 1vh;
              width: 1vh;
              background-color: transparent;
              border: none;
            }

            &::-moz-range-track {
              background-color: transparent;
            }
          }

          & .pointer {
            position: absolute;
            transform: translateX(-50%);
            height: 3vh;
            width: 3vh;
            background-color: var(--player-controls-progress-bar-cursor-color);
            border-radius: 50%;

            & .currentTime {
              position: absolute;
              transform: translateX(calc(-50% + 1.5vh));
              bottom: 4vh;
              font-size: 2.3vh;
              color: var(--player-controls-progress-bar-cursor-color);
            }
          }

          & .timePassed {
            appearance: none;
            background-color: var(--player-controls-progress-bar-color);
            border-radius: 1vh 0 0 1vh;
            height: 100%;
          }
        }

        & p {
          white-space: nowrap;
          font-size: 2.3vh;
          color: white;
        }
      }

      & .scaleProgressBar {
        transform: scale(1);
        transition: transform 200ms ease-in-out;
      }
    }
  }

  & .overlayHide {
    background-color: transparent;
  }

  & .hide {
    display: none;
  }

  & .topContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 7vh;
    padding: 2vh;
    align-items: center;
    justify-content: space-between;

    & .logoPlayer {
      width: 10vw;

      & img {
        width: 100%;
      }
    }

    // & .nextEpisodeContainer {
    //   flex: 1;
    //   opacity: 0;
    // }

    // & .nextEpisodeContainer.mount {
    //   opacity: 1;
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: space-evenly;
    //   align-items: center;

    //   & .nextEpisodeButton {
    //     display: flex;
    //     position: relative;
    //     overflow: hidden;
    //     height: 7vh;
    //     width: 22vh;
    //     background-color: var(--theme-player-nextepisodebutton-backgroundcolor);
    //     border: solid var(--theme-player-nextepisodebutton-bordercolor) 0.3vh;
    //     box-sizing: border-box;
    //     border-radius: 2vh;
    //     font-family: var(--theme-global-fontfamily);
    //     font-size: 1vw;

    //     & .nextEpisodeButtonText {
    //       position: absolute;
    //       align-self: center;
    //       text-align: center;
    //       width: 100%;
    //       padding: 1vh 0;
    //     }

    //     & .nextEpisodeButtonAnimated {
    //       background-color: var(
    //         --theme-player-nextepisodebutton-progressbackgroundcolor
    //       );
    //       height: 100%;
    //       width: 0;
    //     }
    //   }

    //   & .nextEpisodeButtonFocus {
    //     border-color: var(--theme-player-nextepisodebutton-focus-bordercolor);
    //   }
    // }
  }

  & .middleContainer {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 20%;
    left: 2vh;

    & img {
      height: 40vh;
    }

    & .informations {
      font-size: 3vh;
      font-family: var(--theme-global-fontfamily);
      margin-left: 2vh;
    }
  }

  & .forwardBackwardContainer {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: space-around;
    align-items: center;
    background-color: transparent;

    & .svgContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-family: var(--theme-global-fontfamily);
      font-size: 3.5vh;
      width: 15vh;

      & div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 2vh;

        & svg {
          align-self: center;
          width: 5vh;
          max-height: 7vh;
        }
      }
    }
  }
}

// .movieWatchEndPage {
//   height: 100vh;
//   overflow: hidden;
//   position: absolute;
//   z-index: 1;
//   width: 100vw;

//   & .button {
//     background-color: var(--theme-global-buttons-backgroundcolor);
//     border-radius: 0.7vh;
//     margin-right: 3%;
//     align-items: center;
//     display: flex;
//     font-size: var(--theme-global-buttons-fontsize);
//     font-family: var(--theme-global-fontfamily);
//     padding: 1.3vh 1.8vw;
//     box-sizing: border-box;

//     &.focused {
//       background-color: var(--theme-global-buttons-focus-backgroundcolor);
//     }

//     & .countdown {
//       display: flex;
//       align-items: center;
//       width: 2vw;
//       margin-left: 0.6vw;
//     }

//     & svg {
//       width: 4vh;
//       height: 4vh;
//       margin-right: 1vh;
//     }
//   }

//   & > img {
//     width: 100%;
//     position: absolute;
//     bottom: 50%;
//     transform: translateY(50%);
//   }

//   & .titleContainer {
//     position: absolute;
//     bottom: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     text-align: center;
//   }

//   & .bottomContainer {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     right: 0;

//     & .standardLayer {
//       margin-left: 2vw;
//       & .title {
//         text-shadow: 0px 0px 3px black;
//       }

//       & .slider {
//         margin-bottom: 1rem;
//       }
//     }
//   }

//   & .actions {
//     padding: 0 2vw 4vh;
//     display: flex;
//     justify-content: space-between;

//     & button {
//       display: flex;
//       align-items: center;
//       & svg {
//         margin-right: 0.5rem;
//         height: 2rem;
//         width: 2rem;
//       }
//     }

//     & .addToCart {
//       & .countdown {
//         width: 3rem;
//         text-align: center;
//         position: relative;
//       }

//       & .countdownIcon {
//         position: absolute;
//         right: 50%;
//         bottom: 50%;
//         transform: translate(50%, 50%);
//         stroke: var(--theme-global-buttons-fontcolor);
//         height: 2.6rem;
//         width: 2.6rem;
//         margin: 0;
//       }
//     }
//   }
// }

.horizontalVideoContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.playableVideoContainer {
  position: relative;
  width: 100%;
  /* 16:9 https://stackoverflow.com/questions/11243075/css-scale-height-to-match-width-possibly-with-a-formfactor */
  padding-bottom: 56.25%;
  height: 0;

  & div {
    width: 100%;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    margin-bottom: 1%;
    font-size: 3vw;
    color: white;
    @util text-stroke(1px, black, smooth);
  }

  & .waterMark {
    margin: 2vh 0 0 1vh;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & p {
      position: relative;
      padding: 0 1vh 0 1vh;
      font-family: var(--theme-global-fontfamily);
      font-size: var(--theme-videolist-fontsize);
      color: var(--theme-global-playerwatermarkcolor);
      font-weight: bold;
    }

    & .rightRotationText {
      writing-mode: vertical-lr;
    }

    & .leftRotationText {
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }

    & .rotationLeftTextOnLeft {
      transform: translate(-100px, 0) rotate(270deg);
    }

    & .rotationRightTextOnLeft {
      transform: translate(-100px, 0) rotate(90deg);
    }

    & .rotationLeftTextOnRight {
      transform: translate(100px, 0) rotate(270deg);
    }

    & .rotationRightTextOnRight {
      transform: translate(100px, 0) rotate(90deg);
    }
  }
}

.dot {
  position: absolute;
  line-height: 2;
  height: 4vh;
  width: 4vh;
  border-radius: 4vh;
  background-color: white;
  text-align: center;
  color: black;
  font-weight: normal;
  font-family: var(--theme-global-fontfamily);
  font-size: 2vh;
}

.firstDot {
  top: 1.5vh;
  left: 1vw;
}

.secondDot {
  top: 9.5vh;
  left: 46vw;
}

.thirdDot {
  top: 20vh;
  left: 46vw;
}

.fourthDot {
  top: 20vh;
  left: 49.5vw;
}
