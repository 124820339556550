.emptyLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audiobookPlayer {
  display: flex;
  flex-direction: row;
  height: 100vh;

  & .metadataAndPlayer {
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 100%;
    align-items: center;
    justify-content: center;
    & .title {
      font-size: 3vh;
      font-weight: bold;
      margin: 5vh 0 1vh 0;
    }

    & .author {
      margin: 0;
    }

    & .cover {
      margin: 50px;
    }

    & .audioPlayer {
      align-items: center;
      width: 100%;
      display: flex;
      align-items: center;

      & .audioLayout {
        margin: 5vh auto;
        width: 90%;

        & .audioControls {
          display: flex;
          flex-direction: row;
          align-items: center;

          & button {
            all: unset;
            margin-left: 1vw;
            cursor: pointer;
          }

          & .loader {
            margin-left: 1vw;
          }

          & .volumeBarContainer {
            cursor: pointer;
            position: relative;
            width: 0;
            transition: width 0.3s ease;
            transform: translateY(52%);
            height: 1vh;
            & > input {
              appearance: none;
              margin: 0;
              padding: 0;
              border: none;
              width: 100%;
              height: 0.1875rem;
              position: absolute;
              left: 0;
              right: 0;
              z-index: 20;
              transform: translateY(-50%);
              background: transparent;

              &::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 0rem;
                width: 0rem;
                background-color: black;
                border-radius: 50%;
                border: none;
                transition: all 0.3s ease;
              }

              &::-moz-range-thumb {
                -moz-appearance: none;
                height: 0rem;
                width: 0rem;
                background-color: black;
                border-radius: 50%;
                border: none;
                transition: all 0.3s ease;
              }

              &::range-track {
                background-color: transparent;
              }
            }
            & > div {
              position: absolute;
              z-index: 10;
              transform: translateY(-50%);
              height: 0.5vw;
              width: 100%;
              background-color: grey;
              position: relative;
              border-radius: 50px;
              & .previousVolumeBar {
                background-color: black;
                height: 100%;
                border-radius: 50px;
              }
            }
          }

          & .volumeFocus {
            width: 12vw;
            & > input::-webkit-slider-thumb {
              width: 3vw;
              height: 3vw;
            }
            & > input::-moz-range-thumb {
              width: 3vw;
              height: 3vw;
            }
          }

          @media screen and (min-width: 700px) {
            & .volumeFocus {
              width: 8vw;
              & > input::-webkit-slider-thumb {
                width: 2vw;
                height: 2vw;
              }
              & > input::-moz-range-thumb {
                width: 2vw;
                height: 2vw;
              }
            }
          }

          @media screen and (min-width: 1500px) {
            & .volumeFocus {
              width: 4vw;
              & > input::-webkit-slider-thumb {
                width: 1vw;
                height: 1vw;
              }
              & > input::-moz-range-thumb {
                width: 1vw;
                height: 1vw;
              }
            }
          }

          & .duration {
            margin: 0 0 0 3vw;
            color: black;
            font-size: 4vw;
            line-height: 50px;
          }

          @media screen and (min-width: 700px) {
            & .duration {
              margin: 0 0 0 2vw;
              color: black;
              font-size: 3vw;
              line-height: 50px;
            }
          }

          @media screen and (min-width: 1500px) {
            & .duration {
              margin: 0 0 0 1vw;
              color: black;
              font-size: 2vw;
              line-height: 50px;
            }
          }
        }

        & .progressBar {
          width: 100%;
          z-index: 10;
          height: 1vh;
          border-radius: 1vh;
          background-color: grey;
          margin: 0 2vh 0 2vh;
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;

          & > input {
            appearance: none;
            margin: 0;
            padding: 0;
            border: none;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 20;
            background: transparent;

            &::-webkit-slider-thumb {
              -webkit-appearance: none;
              height: 1vh;
              width: 1vh;
              background-color: transparent;
              border: none;
            }

            &::-moz-range-thumb {
              -webkit-appearance: none;
              height: 1vh;
              width: 1vh;
              background-color: transparent;
              border: none;
            }

            &::-ms-thumb {
              -webkit-appearance: none;
              height: 1vh;
              width: 1vh;
              background-color: transparent;
              border: none;
            }
          }

          & .pointer {
            position: absolute;
            transform: translateX(-50%);
            height: 3vh;
            width: 3vh;
            background-color: black;
            border-radius: 50%;

            & .currentTime {
              position: absolute;
              transform: translateX(calc(-50% + 1.5vh));
              bottom: 4vh;
              font-size: 2.3vh;
              color: black;
            }
          }

          & .timePassed {
            appearance: none;
            background-color: black;
            border-radius: 1vh 0 0 1vh;
            height: 100%;
          }
        }

        & .progressBarContainer {
          display: flex;
          flex-direction: row;
          align-items: center;

          & p {
            white-space: nowrap;
            font-size: 2.3vh;
            color: black;
          }
        }

        @media screen and (min-width: 700px) {
          & .progressBarContainer {
            display: flex;
            flex-direction: row;
            align-items: center;

            & p {
              white-space: nowrap;
              font-size: 2.3vh;
              color: black;
            }
          }
        }

        @media screen and (min-width: 1500px) {
          & .progressBarContainer {
            display: flex;
            flex-direction: row;
            align-items: center;

            & p {
              white-space: nowrap;
              font-size: 2.3vh;
              color: black;
            }
          }
        }
      }
    }
  }

  & .contents {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30vh;

    & .contentsTitle {
      font-size: 2vh;
      font-weight: bold;
      margin-bottom: 50px;
    }

    & .content {
      all: unset;
      cursor: pointer;
      width: 60%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & .left {
        display: flex;
        flex-direction: row;
        align-items: center;

        & .playingLogo {
          margin-left: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .audiobookPlayer {
    flex-direction: column;
    align-items: center;
    & .metadataAndPlayer {
      height: auto;
    }
    & .contents {
      padding-top: 0;
      width: 100%;

      & .content {
        width: 80%;
      }
    }
  }
}

.audiobookPlayerMini {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .title {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 0.5vh;
  }

  & .author {
    font-size: small;
    margin-bottom: 2.5vh;
  }

  .controls {
    flex-direction: row;
    display: flex;

    & button {
      all: unset;
      cursor: pointer;
    }
  }

  & .progressBar {
    width: 80vw;
    z-index: 10;
    height: 1vh;
    border-radius: 1vh;
    background-color: grey;
    margin: 0 2vh 0 2vh;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & > input {
      appearance: none;
      margin: 0;
      padding: 0;
      border: none;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 20;
      background: transparent;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 1vh;
        width: 1vh;
        background-color: transparent;
        border: none;
      }

      &::-moz-range-thumb {
        -webkit-appearance: none;
        height: 1vh;
        width: 1vh;
        background-color: transparent;
        border: none;
      }

      &::-ms-thumb {
        -webkit-appearance: none;
        height: 1vh;
        width: 1vh;
        background-color: transparent;
        border: none;
      }
    }

    & .pointer {
      position: absolute;
      transform: translateX(-50%);
      height: 3vh;
      width: 3vh;
      background-color: black;
      border-radius: 50%;

      & .currentTime {
        position: absolute;
        transform: translateX(calc(-50% + 1.5vh));
        bottom: 4vh;
        font-size: 2.3vh;
        color: black;
      }
    }

    & .timePassed {
      appearance: none;
      background-color: black;
      border-radius: 1vh 0 0 1vh;
      height: 100%;
    }
  }
}
