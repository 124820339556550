.audioPlayer {
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  background-color: var(--theme-color);

  & .audioLayout {
    margin: 5vh auto;

    & .audioTitle {
      font-weight: bold;
      font-size: 4vw;
      margin-bottom: 5vh;
      color: var(--main-text-color);
    }

    @media screen and (min-width: 700px) {
      & .audioTitle {
        font-size: 3vw;
        margin-bottom: 5vh;
        font-weight: bold;
        color: var(--main-text-color);
      }
    }

    @media screen and (min-width: 1500px) {
      & .audioTitle {
        font-size: 2vw;
        margin-bottom: 5vh;
        font-weight: bold;
        color: var(--main-text-color);
      }
    }

    & .audioControls {
      display: flex;
      flex-direction: row;
      align-items: center;

      & button {
        all: unset;
        margin-left: 1vw;

        & .jumpTimeText {
          position: absolute;
          color: var(--button-second-text-color);
          width: 10vw;
          height: 10vw;
          text-align: center;
          line-height: 10vw;
          margin: auto;
          font-weight: bold;
          font-size: 2.5vw;
        }

        @media screen and (min-width: 700px) {
          & .jumpTimeText {
            position: absolute;
            color: var(--button-second-text-color);
            width: 6vw;
            height: 6vw;
            text-align: center;
            line-height: 6vw;
            margin: auto;
            font-weight: bold;
            font-size: 1.5vw;
          }
        }

        @media screen and (min-width: 1500px) {
          & .jumpTimeText {
            position: absolute;
            color: var(--button-second-text-color);
            width: 3vw;
            height: 3vw;
            text-align: center;
            line-height: 3vw;
            margin: auto;
            font-weight: bold;
            font-size: 1vw;
          }
        }
      }

      & .playButton {
        background-color: var(--button-main-background-color);
        border-radius: 35%;
      }

      & .volumeBarContainer {
        cursor: pointer;
        position: relative;
        width: 0;
        transition: width 0.3s ease;
        transform: translateY(52%);
        height: 1vh;
        & > input {
          appearance: none;
          margin: 0;
          padding: 0;
          border: none;
          width: 100%;
          height: 0.1875rem;
          position: absolute;
          left: 0;
          right: 0;
          z-index: 20;
          transform: translateY(-50%);
          background: transparent;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 0rem;
            width: 0rem;
            background-color: black;
            border-radius: 50%;
            border: none;
            transition: all 0.3s ease;
          }

          &::-moz-range-thumb {
            -moz-appearance: none;
            height: 0rem;
            width: 0rem;
            background-color: black;
            border-radius: 50%;
            border: none;
            transition: all 0.3s ease;
          }

          &::range-track {
            background-color: transparent;
          }
        }
        & > div {
          position: absolute;
          z-index: 10;
          transform: translateY(-50%);
          height: 0.5vw;
          width: 100%;
          background-color: grey;
          position: relative;
          border-radius: 50px;
          & .previousVolumeBar {
            background-color: black;
            height: 100%;
            border-radius: 50px;
          }
        }
      }

      & .volumeFocus {
        width: 15vw;
        & > input::-webkit-slider-thumb {
          width: 3vw;
          height: 3vw;
        }
        & > input::-moz-range-thumb {
          width: 3vw;
          height: 3vw;
        }
      }

      @media screen and (min-width: 700px) {
        & .volumeFocus {
          width: 10vw;
          & > input::-webkit-slider-thumb {
            width: 2vw;
            height: 2vw;
          }
          & > input::-moz-range-thumb {
            width: 2vw;
            height: 2vw;
          }
        }
      }

      @media screen and (min-width: 1500px) {
        & .volumeFocus {
          width: 6vw;
          & > input::-webkit-slider-thumb {
            width: 1vw;
            height: 1vw;
          }
          & > input::-moz-range-thumb {
            width: 1vw;
            height: 1vw;
          }
        }
      }

      & .duration {
        margin: 0 0 0 3vw;
        color: var(--player-controls-text-color);
        font-size: 4vw;
        line-height: 50px;
      }

      & .loader {
        margin-left: 1vw;
      }

      @media screen and (min-width: 700px) {
        & .duration {
          margin: 0 0 0 2vw;
          color: var(--player-controls-text-color);
          font-size: 3vw;
          line-height: 50px;
        }
      }

      @media screen and (min-width: 1500px) {
        & .duration {
          margin: 0 0 0 1vw;
          color: var(--player-controls-text-color);
          font-size: 2vw;
          line-height: 50px;
        }
      }
    }

    & .progressBar {
      width: 100%;
      z-index: 10;
      height: 1vh;
      border-radius: 1vh;
      background-color: grey;
      margin: 0 2vh 0 2vh;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      & > input {
        appearance: none;
        margin: 0;
        padding: 0;
        border: none;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 20;
        background: transparent;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          height: 1vh;
          width: 1vh;
          background-color: var(--player-controls-progress-bar-cursor-color);
          border: none;
        }

        &::-moz-range-thumb {
          -webkit-appearance: none;
          height: 1vh;
          width: 1vh;
          background-color: var(--player-controls-progress-bar-cursor-color);
          border: none;
        }

        &::-ms-thumb {
          -webkit-appearance: none;
          height: 1vh;
          width: 1vh;
          background-color: var(--player-controls-progress-bar-cursor-color);
          border: none;
        }
      }

      & .pointer {
        position: absolute;
        transform: translateX(-50%);
        height: 3vh;
        width: 3vh;
        background-color: var(--player-controls-progress-bar-cursor-color);
        border-radius: 50%;

        & .currentTime {
          position: absolute;
          transform: translateX(calc(-50% + 1.5vh));
          bottom: 4vh;
          font-size: 2.3vh;
          color: var(--player-controls-progress-bar-cursor-color);
        }
      }

      & .timePassed {
        appearance: none;
        background-color: white;
        border-radius: 1vh 0 0 1vh;
        height: 100%;
      }
    }

    & .progressBarContainer {
      display: flex;
      flex-direction: row;
      width: 80vw;
      align-items: center;

      & p {
        white-space: nowrap;
        font-size: 2.3vh;
        color: var(--player-controls-progress-bar-color);
      }
    }

    @media screen and (min-width: 700px) {
      & .progressBarContainer {
        display: flex;
        flex-direction: row;
        width: 70vw;
        align-items: center;

        & p {
          white-space: nowrap;
          font-size: 2.3vh;
          color: var(--player-controls-progress-bar-color);
        }
      }
    }

    @media screen and (min-width: 1500px) {
      & .progressBarContainer {
        display: flex;
        flex-direction: row;
        width: 50vw;
        align-items: center;

        & p {
          white-space: nowrap;
          font-size: 2.3vh;
          color: var(--player-controls-progress-bar-color);
        }
      }
    }
  }
}

.audioPlayerMini {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--theme-color);

  .audioControls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & button {
    all: unset;
    margin-left: 1vw;
  }

  & .playButton {
    background-color: var(--button-main-background-color);
    border-radius: 35%;
  }

  & .progressBar {
    width: 70vw;
    z-index: 10;
    height: 1vh;
    border-radius: 1vh;
    background-color: grey;
    margin: 0 2vh 0 2vh;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > input {
      appearance: none;
      margin: 0;
      padding: 0;
      border: none;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 20;
      background: transparent;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 1vh;
        width: 1vh;
        background-color: var(--player-controls-progress-bar-cursor-color);
        border: none;
      }

      &::-moz-range-thumb {
        -webkit-appearance: none;
        height: 1vh;
        width: 1vh;
        background-color: var(--player-controls-progress-bar-cursor-color);
        border: none;
      }

      &::-ms-thumb {
        -webkit-appearance: none;
        height: 1vh;
        width: 1vh;
        background-color: var(--player-controls-progress-bar-cursor-color);
        border: none;
      }
    }

    & .pointer {
      position: absolute;
      transform: translateX(-50%);
      height: 3vh;
      width: 3vh;
      background-color: var(--player-controls-progress-bar-cursor-color);
      border-radius: 50%;

      & .currentTime {
        position: absolute;
        transform: translateX(calc(-50% + 1.5vh));
        bottom: 4vh;
        font-size: 2.3vh;
        color: var(--player-controls-progress-bar-cursor-color);
      }
    }

    & .timePassed {
      appearance: none;
      background-color: white;
      border-radius: 1vh 0 0 1vh;
      height: 100%;
    }
  }
}
