.ebookReader {
  & button {
    cursor: pointer;
  }

  & .loader {
    position: absolute;
    right: 50%;
    bottom: 50%;
    z-index: 10;
  }

  & .topContainer {
    position: fixed;
    top: 0px;
    z-index: 2;
    width: 100%;
    height: 8vh;
    border-width: 1px;
    border-color: grey;
    border-style: solid;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 1vh;
    color: black;

    .topContainerRight {
      position: fixed;
      right: 0;
      margin-right: 1vw;
    }
  }

  & .bottomContainer {
    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;

    & button {
      background-color: white;
      margin: 1vh 1vw;
      border: 1px solid gray;
    }
  }

  & .ebookMenuContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: #00000040;
    color: black;

    .ebookMenu {
      display: flex;
      flex-direction: column;
      overflow: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
      background-color: white;
      height: 100%;
      width: 260px;

      &::-webkit-scrollbar {
        display: none;
      }

      .ebookMenuTitle {
        font-size: larger;
        margin: 2vh 0 2vh 1vw;
        font-weight: bold;
      }

      & button {
        color: black;
        display: flex;
        flex-direction: row;
        font-family: sans-serif;
        align-items: center;
        cursor: pointer;

        & p {
          margin-left: 1vw;
        }
      }

      & .ebookMenuEntry {
        font-weight: bold;
        border-top: 1px solid black;
      }

      & .ebookMenuContent {
        cursor: pointer;
        border-top: 1px solid gray;
        color: dimgray;
        text-align: left;
      }
    }
  }

  & .ebookMenuSettingsContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: #00000040;
    color: black;

    .ebookMenuSettings {
      position: absolute;
      right: 0;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
      background-color: white;
      height: 100%;
      width: 260px;

      &::-webkit-scrollbar {
        display: none;
      }

      .ebookMenuTitle {
        font-size: larger;
        margin: 3vh 0 5vh 1vw;
        font-weight: bold;
      }

      & .ebookMenuEntry {
        display: flex;
        flex-direction: row;
        font-family: sans-serif;
        align-items: center;
        cursor: pointer;
        margin-left: 1vw;
        margin-bottom: 2vh;

        & button {
          color: black;
          padding: 20px;
        }

        & .dayButton {
          border: 1px solid gray;
          margin-right: 10px;
        }
        & .sepiaButton {
          border: 1px solid gray;
          background-color: bisque;
          margin-right: 10px;
        }
        & .nightButton {
          border: 1px solid gray;
          background-color: black;
          color: white;
        }

        & .selectedOption {
          font-weight: bold;
        }
      }
    }
  }

  & .ebookBookmarksModalContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: #00000040;
    color: black;

    & .ebookBookmarksModal {
      position: absolute;
      right: 0;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
      background-color: white;
      height: 100%;
      width: 280px;

      .ebookMenuTitle {
        font-size: larger;
        margin: 3vh 0 5vh 1vw;
        font-weight: bold;
      }

      & .bookmark {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 5px 0 10px;

        & .bookmarkButton {
          display: flex;
          flex-direction: row;
          align-items: center;

          & .bookmarkTitle {
            width: 150px;
            margin-right: 5px;
            text-align: left;
          }
        }
      }
    }
  }
}
